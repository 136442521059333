import styles from '@style/IndustriesSection.module.css'
import { useState } from 'react'
export default function IndustriesSection() {
  const [activeIndustryCardIndex, setActiveIndustryCardIndex] = useState(0)

  function updateActiveCard(index: number) {
    if (activeIndustryCardIndex === index) {
      return
    }

    setActiveIndustryCardIndex(index)
  }

  const industries = [
    {
      title: 'Financial Services',
      caseName: 'All of the branches connected to one place',
      caseDescription:
        'Ziraat Bank, founded in 1863, being the oldest bank in Turkey has many legacy applications. All the applications are integrated to Monosign flawlessly with the help of Integration Platform of Monosign.',
      svgName: 'ziraat-bankasi-logo-case',
      imageName: 'ziraat-success.jpeg',
    },
    {
      title: 'Catering',
      caseName: 'Identity for the Largest Fast-Food Chain',
      caseDescription:
        'With over 500 privileged employees, McDonalds manages access to their 15+ applications via Monosign.',
      svgName: 'mcdonalds-success',
      imageName: 'mcdonalds-success.jpg',
    },
    {
      title: 'Pharmacutical',
      caseName: 'Data is Critical in Pharma',
      caseDescription:
        'Abdi Ibrahim has had a phenomenal identity governance process to force audit and compliance regulations to all of the IAM spectrum with Monosign.',
      svgName: 'abdi-logo-case',
      imageName: 'abdi-success.jpeg',
    },
    {
      title: 'Transportation',
      caseName:
        "Europe's Largest Airport with 5000 internal/external workforce identities",
      caseDescription:
        'Istanbul Airport hosts hundreds of flights everyday and all landings are critical. Every access, authorization, authentication, verification is provided securely by Monosign.',
      svgName: 'iga-istanbul-logo-case',
      imageName: 'iga-success.jpeg',
    },
  ]

  return (
    <section id={styles.industries}>
      <div className={styles.industriesSection}>
        <div className={styles.industriesSectionTitle}>
          <h2>Monofor fits all industries, no matter the stack</h2>
          <p>
            With over 7000 integrations, we support any application your
            organization uses. From retail to government agencies, we cover all
            use cases.
          </p>
        </div>
        <div className={styles.industriesList}>
          <ul className={styles.industryListNavigation}>
            {industries.map((item, index) => (
              <li key={index}>
                <a
                  href="#"
                  className={`industryNavItem ${
                    activeIndustryCardIndex == index && 'selected'
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    updateActiveCard(index)
                  }}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.industryCards}>
            {industries.map((item, index) => (
              <div
                className={`industryCard ${
                  activeIndustryCardIndex === index && 'selected'
                }`}
                key={index}
              >
                <div
                  className={styles.industryImage}
                  style={{
                    background: `url('/images/customer-success-images/${item.imageName}') no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                ></div>
                <div className={styles.industryCaseInfo}>
                  <h5>{item.caseName}</h5>
                  <p>{item.caseDescription}</p>
                  <img
                    src={`/images/customer-success-logos/${item.svgName}.svg`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
