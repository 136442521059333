import Image from 'next/image'
import styles from '@style/WhyMonoforSection.module.css'

export default function WhyMonoforSection() {
  return (
    <section id={styles.whyMonofor}>
      <div className={styles.whyMonoforSection}>
        <div className={styles.whyMonoforTitle}>
          <h2>What makes Monofor different?</h2>
          <p>
            With over 7000 integrations, we support any application your
            organization uses. From retail to government agencies, we cover all
            use cases.
          </p>
        </div>
        <div className={styles.featureCards}>
          <div className={styles.featuresRow}>
            <div className={`${styles.featureCard} ${styles.modularized}`}>
              <Image
                src="/images/lego.png"
                width={107}
                height={107}
                alt="Modularized Architecture descripted as lego bricks"
              />
              <div className={styles.modularizedInfo}>
                <h5>Modularized Architecture</h5>
                <p>
                  Monofor’s products are built with the latest technologies, and
                  are fully modularized.{' '}
                </p>
                <p>
                  Need MFA in addition to your SSO? <br />
                  Added almost instantly.
                </p>
              </div>
            </div>
            <div className={`${styles.featureCard} ${styles.integrations}`}>
              <div className={styles.integrationsWall}>
                <div className={styles.integrationsWallImage}></div>
              </div>
              <div className={styles.integrationsInfo}>
                <h5>Integrates with everything</h5>
                <p>
                  With over 7000 integrations, Monofor quickly connects with any
                  product or service you use.
                </p>
                <p>
                  We support <strong>SAML 2</strong>,{' '}
                  <strong>OpenID/OIDC</strong>, <strong>OAuth 2</strong>,{' '}
                  <strong>JWT</strong> and more connections.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.featuresRow}>
            <div className={`${styles.featureCard} ${styles.features}`}>
              <div className={styles.featuresWall}>
                <div
                  className={`${styles.featuresWallImage} ${styles.firstRow}`}
                ></div>
                <div
                  className={`${styles.featuresWallImage} ${styles.secondRow}`}
                ></div>
                <div
                  className={`${styles.featuresWallImage} ${styles.thirdRow}`}
                ></div>
              </div>
              <div className={styles.featuresInfo}>
                <h5>Complete Solution for Access Management</h5>
                <p>
                  We cover almost every case for managing identities. SSO, MFA,
                  Password Management, Passwordless, MFA for Computer Logins,
                  PAM, IGM. You name it.
                </p>
              </div>
            </div>
            <div className={`${styles.featureCard} ${styles.cloud}`}>
              <Image
                src="/images/server-cloud.png"
                width={107}
                height={107}
                alt="Use private cloud or self-hosted descripted as server cluster"
              />
              <div className={styles.cloudInfo}>
                <h5>
                  Self-Hosted, Private Cloud
                  <br />
                  or Cloud
                </h5>

                <p>
                  Monofor&apos;s products can be installed pretty much anywhere
                  thanks to its modern foundations.
                </p>
                <p>
                  Install on your own server as self-hosted <br />
                  or run on your private cloud.
                </p>
                {/* <button className={styles.roundedOutlineButton}>Speak to one of our experts</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
