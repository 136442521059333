import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
// Default theme
import '@splidejs/react-splide/css'
import styles from '@style/Testimonials.module.css'
import ReactStars from 'react-stars'

export default function Testimonials() {
  return (
    <section id={styles.testimonials}>
      <div className={styles.testimonialsContainer}>
        <div className={styles.testimonialsTitle}>
          <h2>What our customers are saying</h2>
          <a
            href="https://www.gartner.com/reviews/market/identity-governance-administration/vendor/monofor/reviews?utm_source=monofor&utm_medium=referral&utm_campaign=widget&utm_content=ZTMxYzYwYWMtYzdmMi00NTZhLWE5MjQtM2VmMDc1ZjIwYjdk&marketSeoName=identity-governance-administration&vendorSeoName=monofor"
            className={styles.gartnerLogo}
            target="_blank"
          >
            <svg
              width="104"
              height="25"
              viewBox="0 0 104 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1240_10650)">
                <path
                  d="M102.716 8.61865L102.426 12.1872H100.98C98.6648 12.1872 97.4592 14.0679 97.4592 15.8522V23.9055H93.3604V8.61865H97.2664L97.3628 10.5476C98.1826 9.24557 99.6293 8.61865 101.365 8.61865H102.716Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M74.1196 23.8572H69.9723V15.804C69.9723 14.1162 69.8277 11.9943 67.4648 11.9943C65.1983 11.9943 64.2819 13.6339 64.2819 15.5629V23.8572H60.1348V8.6187H63.9444L64.0408 10.5476C64.8608 9.24561 66.4039 8.23291 68.6221 8.23291C72.7692 8.23291 74.0713 11.2228 74.0713 14.3091V23.8572H74.1196Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M58.3502 20.289L58.0609 23.761C57.0482 24.0503 56.1802 24.0987 55.1193 24.0987C51.2131 24.0987 50.104 22.3144 50.104 18.7459V4.27881H54.2512V8.57068H58.3502L58.0609 12.1392H54.2512V18.4565C54.2512 19.9031 54.6371 20.6747 56.132 20.6747C56.7588 20.6747 57.4822 20.6265 58.3502 20.289Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M48.7539 8.61865L48.4164 12.1872H47.3072C44.9924 12.1872 43.8352 14.0679 43.8352 15.8522V23.9055H39.688V8.61865H43.594L43.6904 10.5476C44.5102 9.24557 45.957 8.61865 47.7412 8.61865H48.7539Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M21.0737 22.1696C18.2767 23.6646 15.4798 24.5325 11.7666 24.4361C4.96712 24.2432 0.0483398 19.9995 0.0483398 12.8143C0.0483398 5.62898 5.01534 1.09599 11.8148 0.999544C15.2387 0.903097 18.3249 1.81934 20.7362 3.94116L17.8427 6.88279C16.3478 5.58076 14.1778 4.85741 11.5255 4.95386C7.28184 5.14675 4.67778 8.28126 4.62956 12.5732C4.58133 17.2026 7.42651 20.3853 11.8631 20.5301C13.9367 20.5783 15.4316 20.289 16.7336 19.7101V14.9843H12.1042L12.3935 11.0782H21.0737V22.1696Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M87.3327 14.5021C87.1879 12.5731 85.7413 11.3675 83.7641 11.4158C81.7387 11.5122 80.4367 12.8142 80.1474 14.5021H87.3327ZM91.4316 17.6848H80.0992C80.4367 19.6137 81.9798 20.8194 83.8607 20.8675C85.7413 20.9157 86.754 20.3371 88.1042 18.9869L90.8048 21.0605C88.8758 23.4234 86.6093 24.2913 83.9571 24.2913C79.3758 24.2913 75.9038 21.0605 75.9038 16.2863C75.9038 11.5122 79.3276 8.28125 83.7641 8.28125C88.6829 8.28125 91.3353 11.2711 91.3353 16.5274V17.6848H91.4316Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M33.1294 17.2025H32.5508C30.6218 17.2025 27.1498 17.2989 27.198 19.3244C27.198 22.2177 33.1294 21.3014 33.1294 17.8294V17.2025ZM37.132 13.875V23.8572H33.5153L33.4188 22.3623C32.3097 23.7609 30.4772 24.2431 28.6447 24.2431C25.8478 24.2431 23.1954 22.8445 23.1472 19.6137C23.0508 14.5502 28.8375 14.1644 32.5026 14.1644H33.2258V14.0197C33.2258 12.3319 32.1167 11.4157 30.0431 11.4157C28.5482 11.4157 27.0532 11.9943 25.9923 13.0552L23.6294 10.6441C25.7996 8.90805 28.0177 8.23291 30.429 8.23291C35.0101 8.23291 37.132 10.403 37.132 13.875Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M100.738 21.9764V21.253H101.221C101.462 21.253 101.751 21.3012 101.751 21.5905C101.751 21.9764 101.462 21.9764 101.172 21.9764H100.738ZM100.738 22.2657H101.172L101.799 23.2784H102.185L101.51 22.2175C101.848 22.1693 102.137 21.9764 102.137 21.5423C102.137 21.0601 101.848 20.8673 101.317 20.8673H100.401V23.2302H100.738V22.2657ZM101.172 24.1464C102.282 24.1464 103.246 23.2784 103.246 22.0728C103.246 20.9155 102.282 20.0474 101.172 20.0474C100.063 20.0474 99.0989 20.9155 99.0989 22.0728C99.0507 23.2784 100.015 24.1464 101.172 24.1464ZM99.4846 22.121C99.4846 21.1566 100.208 20.4332 101.172 20.4332C102.089 20.4332 102.86 21.1566 102.86 22.121C102.86 23.1337 102.137 23.857 101.172 23.857C100.208 23.8088 99.4846 23.0855 99.4846 22.121Z"
                  fill="#1E1E1E"
                />
              </g>
              <defs>
                <clipPath id="clip0_1240_10650">
                  <rect width="104" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <Splide
          aria-label="Testimonials for Monofor on Gartner Peer Insights"
          hasTrack={false}
          options={{
            classes: {
              arrows: 'splide__arrows',
              arrow: 'splide__arrow',
              prev: 'splide__arrow--prev',
              next: 'splide__arrow--next',
            },
            pagination: false,
            fixedWidth: '30%',
            perMove: 3,
            breakpoints: {
              1024: {
                gap: '60px',
                fixedWidth: '30%',
                perMove: 3,
              },
              640: {
                gap: '1rem',
                fixedWidth: '100%',
                perMove: 1,
              },
            },
          }}
        >
          <SplideTrack>
            <SplideSlide>
              <figure className={styles.testimonialItem}>
                <blockquote>
                  You can access Monofor Authenticator from anywhere, whether it
                  be a mobile device or a computer. I also like the fact that
                  you can access all the apps using single credentials. Great
                  customer service as well. They are very accommodating and
                  helpful whenever you have concern or issue that you want to
                  report. Definitely highly recommended.
                </blockquote>
                <div className={styles.testimonialItemBottomBar}>
                  <figcaption>
                    Reviewed <cite>Feb 17, 2022</cite>
                  </figcaption>
                  <ReactStars
                    color1="#dedede"
                    color2="#000000"
                    edit={false}
                    value={5}
                    half={true}
                  />
                </div>
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className={styles.testimonialItem}>
                <blockquote>
                  The platform is truly flexible. From our organization&apos;s
                  perspective, we have several internal and external
                  applications that spans across various teams. Monosign
                  seamlessly integrates with the aplications and has been our
                  go-to SSO solution ever since. <br />
                  <br />
                  The customer support that the team provides is fantastic. The
                  turn around time for solving issues is super quick.
                </blockquote>
                <div className={styles.testimonialItemBottomBar}>
                  <figcaption>
                    Reviewed <cite>Feb 22, 2022</cite>
                  </figcaption>
                  <ReactStars
                    color1="#dedede"
                    color2="#000000"
                    edit={false}
                    value={4}
                  />
                </div>
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className={styles.testimonialItem}>
                <blockquote>
                  Starting from day one, the product and the team was great. We
                  created an automation flow for all our identities and access
                  requests. Connected them with our main identity sources
                  (Active Directory and some LDAPs for Linux). We are also
                  managing our cloud identities, provision and deprovisioning
                  processes.
                </blockquote>
                <div className={styles.testimonialItemBottomBar}>
                  <figcaption>
                    Reviewed <cite>Aug 18, 2021</cite>
                  </figcaption>
                  <ReactStars
                    color1="#dedede"
                    color2="#000000"
                    edit={false}
                    value={5}
                  />
                </div>
              </figure>
            </SplideSlide>
            <SplideSlide>
              <figure className={styles.testimonialItem}>
                <blockquote>
                  Overall experience has been fruitful, Monofor provides a range
                  of features that help in management of cloud identifiers,
                  biometric solution for tailgating and security features that
                  keeps us secure and worry free. <br />
                  <br />
                  Architectural Governance, Intrusion Detection system and Event
                  tracking have been stand out performance for us.
                </blockquote>
                <div className={styles.testimonialItemBottomBar}>
                  <figcaption>
                    Reviewed <cite>2 Feb, 2022</cite>
                  </figcaption>
                  <ReactStars
                    color1="#dedede"
                    color2="#000000"
                    edit={false}
                    value={4}
                  />
                </div>
              </figure>
            </SplideSlide>
          </SplideTrack>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <svg
                width="24"
                height="13"
                viewBox="0 0 24 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.69605V5.30172H19.6678V5.1307L14.8791 0.342046V0H17.7865L23.9434 6.15684V6.84093L17.7865 12.9978H14.8791V12.6557L19.6678 7.86707V7.69605H0Z"
                  fill="#1E1E1E"
                />
              </svg>
            </button>
            <button className="splide__arrow splide__arrow--next">
              <svg
                width="24"
                height="13"
                viewBox="0 0 24 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.69605V5.30172H19.6678V5.1307L14.8791 0.342046V0H17.7865L23.9434 6.15684V6.84093L17.7865 12.9978H14.8791V12.6557L19.6678 7.86707V7.69605H0Z"
                  fill="#1E1E1E"
                />
              </svg>
            </button>
          </div>
        </Splide>
      </div>
    </section>
  )
}
