import styles from '@style/Hero.module.css'
import { motion } from 'framer-motion'
import Link from 'next/link'

export default function Hero() {
  return (
    <section className={styles.heroContainer}>
      <div className={styles.heroContent}>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.8 }}
        >
          Empowered Identity &amp; <br />
          Access
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.8, delay: 0.8 }}
        >
          IAM, PAM and IGA products that cover all your <br />
          identity needs.
        </motion.p>
        <motion.div
          className={styles.heroCtaButtons}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeIn', duration: 0.8, delay: 1.6 }}
        >
          <Link href="/try/now">
            <button className={styles.heroCtaButton}>Start Now</button>
          </Link>
          <Link href="/contact">
            <button className={styles.heroCtaOutlineButton}>
              Talk with our experts
            </button>
          </Link>
        </motion.div>
      </div>
      <div className={styles.heroImage}></div>
    </section>
  )
}
