import Layout from '@comp/layout'
import Hero from '@comp/hero'
import CustomerList from '@comp/customers-list'
import ProductsSection from '@comp/products-section'
import IndustriesSection from '@comp/industries-section'
import WhyMonoforSection from '@comp/why-monofor-section'
import FinalCTASection from '@comp/final-cta-section'
import Head from 'next/head'
import Testimonials from '@comp/testimonials'

export default function Home() {
  return (
    <>
      <Head>
        <title>Monofor – Empowered Identity and Access</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          name="description"
        />
        <meta
          content="Monofor – Empowered Identity and Access"
          property="og:title"
        />
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          property="og:description"
        />
        <meta content="/images/ogImage.jpg" property="og:image" />
        <meta
          content="Monofor – Empowered Identity and Access"
          property="twitter:title"
        />
        <meta
          content="IAM, PAM and IGA products that cover all your 
          identity needs."
          property="twitter:description"
        />
        <meta content="/images/ogImage.jpg" property="twitter:image" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
      </Head>
      <Layout>
        <main>
          <Hero />
          <CustomerList text="Trusted by large enterprises worldwide" />
          <ProductsSection />
          <IndustriesSection />
          <WhyMonoforSection />
          <Testimonials />
          <FinalCTASection message={''} />
        </main>
      </Layout>
    </>
  )
}
