import styles from '@style/CustomerLogos.module.css'
import Image from 'next/image'

type Props = {
  text: string
  bgColor?: string
}

export default function CustomerList({ text, bgColor = 'clear' }: Props) {
  return (
    <section
      className={styles.customerLogosContainer}
      style={{ backgroundColor: bgColor ?? 'clear' }}
    >
      <h5>{text}</h5>
      <div className={styles.customerLogos}>
        <div>
          <Image
            src="/images/logos/ziraat-bankasi-logo.svg"
            width={131}
            height={29}
            alt="Ziraat bankasi is using Monofor products."
          />
          <Image
            src="/images/logos/fener-logo.svg"
            width={44}
            height={43}
            alt="Fenerbahçe Sports Club is using Monofor products."
          />
          <Image
            src="/images/logos/mcdonalds.svg"
            width={43}
            height={39}
            alt="Ziraat bankasi is using Monofor products."
          />
        </div>
        <div>
          <Image
            src="/images/logos/united-biscuits.svg"
            width={42}
            height={35}
            alt="Ziraat bankasi is using Monofor products."
          />
          <Image
            src="/images/logos/iga-istanbul-logo.svg"
            width={129}
            height={21}
            alt="Ziraat bankasi is using Monofor products."
          />
        </div>
        <div>
          <Image
            src="/images/logos/godiva-logo.svg"
            width={54}
            height={39}
            alt="Godiva is using Monofor products."
          />
          <Image
            src="/images/logos/stc.svg"
            width={65}
            height={33}
            alt="Saudi Telecom Company (STC) is using Monofor products."
          />
          <Image
            src="/images/logos/odeabank.svg"
            width={94}
            height={19}
            alt="Odeabank is using Monofor products."
          />
        </div>
      </div>
    </section>
  )
}
