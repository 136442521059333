import styles from '@style/FinalCTASection.module.css'
import CustomerList from '@comp/customers-list'
import Link from 'next/link'

type Props = {
  message: string
}

export default function FinalCTASection({ message }: Props = { message: '' }) {
  return (
    <section id="finalCTA">
      <div className={styles.finalCTASection}>
        <h3>{message || 'Ready to start managing identities?'}</h3>
        <p>
          Start your trial now, or contact us to use Monofor products
          self-hosted <br />
          or your private cloud.
        </p>
        <div className={styles.finalCtaButtons}>
          <Link href="/try/now">
            <button className={styles.finalCtaButton}>Start Now</button>
          </Link>
          <Link href="/contact">
            <button className={styles.finalCtaOutlineButton}>
              Talk with our experts
            </button>
          </Link>
        </div>
        <CustomerList text="Join customers who secure their identities via Monofor" />
      </div>
    </section>
  )
}
